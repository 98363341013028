import React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Seo from '../components/seo';
import Header from '../components/Header';
import Footer from '../components/footer';
import Layout from '../components/layout';
import '../sass/main.scss';
import NomineesPet from '../components/BlockLibrary/HamboneAward/NomineesPet';
import Blocks from '../components/blocks';

class NomineesPetTemplate extends React.Component {
     componentDidMount() {
          const brower = typeof window !== `undefined`;
          const nomineesPet = get(this.props, 'data.contentfulHamboneNomineesPet');
          this.props.setInfoArticle(nomineesPet.infoArticle);

          if (brower) {
               var getOmValue = localStorage.getItem('Petinsurance_OM');
               this.props.setTrackingCode(getOmValue);
               var getPhoneValue = localStorage.getItem('Petinsurance_TrackingPhone');
               this.props.setTrackingPhone(getPhoneValue);
          }
     }

     render() {
          const nomineesPet = get(this.props, 'data.contentfulHamboneNomineesPet');

          const path = get(this.props, '*');
          // const seoTitleTag = nomineesPet.seoTitleTag ? nomineesPet.seoTitleTag : `${nomineesPet.breadcrumbValue} | Pet Health Insurance & Tips`;
          // const seoMetaDescription = nomineesPet.seoMetaDescription ? nomineesPet.seoMetaDescription.seoMetaDescription : nomineesPet.shortDescription;

          const trackingCodeDefault = get(this.props, 'data.getTrackingCodeDefault');
          const trackingCode = nomineesPet.trackingCode != null ? nomineesPet.trackingCode : trackingCodeDefault.trackingCode;
          let defaultSeoTitle = null;
          let defaultSeoMetaDescription = null;

          if (nomineesPet.nomineesOfTheYear?.nomineesYear >= 2021) {
               defaultSeoTitle = `Hambone Award ${nomineesPet.nomineesOfTheYear.nomineesYear} Nominees | ${nomineesPet.nomineesPetName} | Nationwide`;
               defaultSeoMetaDescription = `Meet ${nomineesPet.nomineesPetName}, one of Nationwide's nominees for the ${nomineesPet.nomineesOfTheYear.nomineesYear} Hambone Award, given to pets who have shown resilience in the face of illness or accidents.`;
          }

          return (
               <Layout>
                    <Seo
                         pageName={nomineesPet.omniturePageNameLegacy || nomineesPet.contentName}
                         specificPhone={nomineesPet.specificPhone}
                         trackingCode={trackingCode}
                         title={nomineesPet.seoTitleTag || defaultSeoTitle}
                         path={path}
                         canonical={nomineesPet.seoCanonicalUrl ? nomineesPet.seoCanonicalUrl.seoCanonicalUrl : ''}
                         description={nomineesPet.seoMetaDescription?.seoMetaDescription || defaultSeoMetaDescription}
                         robots={nomineesPet.robotsDirective ? nomineesPet.robotsDirective.robotsDirective : ''}
                         image={nomineesPet.socialImage ? nomineesPet.socialImage : nomineesPet.featuredImage}
                         // category={nomineesPet.category}
                         article={nomineesPet}
                         // hasBreadcrumb={true}
                         showAntiFlickerSnippet={nomineesPet.showAntiFlickerSnippet}
                    />
                    <Header header={nomineesPet.header}></Header>
                    <main id="main-content" role="main">
                         <NomineesPet nomineesPet={nomineesPet} />
                         {nomineesPet.rows && <Blocks blocks={nomineesPet.rows}></Blocks>}
                    </main>
                    <Footer footer={nomineesPet.footer}></Footer>
               </Layout>
          );
     }
}

const mapDispatchToProps = (dispatch) => {
     return {
          setInfoArticle: (data) => dispatch({ type: `GET_INFO_ARTICLE`, payload: data }),
          setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
          setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone })
     };
};
const mapStateToProps = (state) => {
     return {
          state
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(NomineesPetTemplate);

export const pageQuery = graphql`
     query NomineesPetTemplateBySlug($slug: String!) {
          contentfulHamboneNomineesPet(slug: { eq: $slug }) {
               ...ContentfulHamboneNomineesPetFields
          }
          getTrackingCodeDefault: contentfulPage(contentful_id: { eq: "wChsWYdrCJ5doI8QdVkBk" }) {
               id
               trackingCode
          }
     }
`;
