import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIsPreviewStatus } from '../../../state/app';
import NomineesArticle from './NomineesArticle';

const NomineesPetBlock = ({ nomineesPet }) => {
     return <NomineesArticle nomineesPet={nomineesPet} />;
};

const mapStateToProps = (state) => {
     return {
          isPreview: getIsPreviewStatus(state)
     };
};

NomineesPetBlock.propTypes = {
     isPreview: PropTypes.bool
};

const NomineesPet = connect(mapStateToProps)(NomineesPetBlock);

export default NomineesPet;
