import React, { Fragment } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

const HamboneSlider = (slide) => {
     const slider = slide.slide;
     return (
          <>
               <Swiper navigation={true} className="mySwiper" loop={true} pagination={{clickable: true}} modules={[Pagination, Navigation]}>
                    {slider?.listImages?.map((item) => {
                         const urlSlide = item.image.file.url;
                         return (
                              <Fragment key={item.id}>
                                   <SwiperSlide>
                                             <div className="hambone-slider-banner" style={{ backgroundImage: `url(${urlSlide})` }}>
                                                  
                                             </div>
                                   </SwiperSlide>
                              </Fragment>
                         )
                    }
                    )}
               </Swiper>
          </>
     )
}

export default HamboneSlider;